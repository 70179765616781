export const garnishes = [
  { "title": "chickpeas" },
  {
    "title": "cilantro",
    // "source": "Balance Farms",
  },
  { "title": "cucumber" },
  { "title": "kimchi pickles" },
  { "title": "red cabbage" },
  { "title": "roasted garlic cloves" },
  { "title": "seasonal hot peppers" },
  {
    "title": "seasonal sprouts",
    // "source": "Balance Farms",
  },
  { "title": "tomato" },
  { "title": "chow mein noodles" },
  { "title": "crushed peanuts" },
  { "title": "daikon radish" },
  // { "title": "pickled carrots" },
  { "title": "red onion" },
  { "title": "scallions" },
  { "title": "seasonal potatoes" },
  { "title": "sesame seeds" },
  { "title": "whole peanuts" },
  // { "title": "curried cashews" },
  // {
  //   "title": "microgreens",
  //   "source": "Balance Farms",
  // },
  // { "title": "kimchi radish" },
  // {
  //   "title": "sunflower microgreens",
  //   "source": "Balance Farms",
  // },
]
