export const tacos = [

  // {
  //   "image": "https://balancegrille.com/wp-content/uploads/2023/11/Bahn-Mi-Tacos.webp",
  //   "title": "bahn mi",
  //   "price": "$7",
  //   "flavorTags": [
  //     { "tag": "Spicy 2/5" },
  //     { "tag": "Savory" },
  //     { "tag": "Tangy" }
  //   ],
  //   "description": "Vietnamese-inspired Bahn Mi flavors over grilled chicken breast. Served with pickled carrots, red cabbage, cucumbers, jalapeños (to add a bit of heat) and cilantro.",
  //   // "descriptionNotes": "Contains dairy.",
  //   "dietary": [
  //     { "type": "vegetarian", "title": "Vegetarian Option", "subtitle": "Choose Tofu or Lentils as a protein." }
  //   ],
  //   "nutrition": [
  //     { "value": "390", "unit": "Calories" },
  //     { "value": "1g", "unit": "Protein" },
  //     { "value": "12g", "unit": "Total Carbohydrate" },
  //     { "value": "34g", "unit": "Total Fat" },
  //   ]
  // },

  {
    "image": "https://balancegrille.com/wp-content/uploads/2025/03/the-ranch-tacos.webp",
    "title": "the ranch",
    "price": "$8",
    "flavorTags": [
      { "tag": "Salty" },
      { "tag": "Citrusy" },
      { "tag": "Dilly" },
    ],
    "description": "Bold American flavors with an Asian twist. This limited-edition taco features salty, citrusy, and dill notes with a touch of spice. Juicy grilled chicken—our recommended protein—drenched in our new Asian Ranch sauce, then topped with fresh cucumbers, red cabbage, broccoli microgreens, and jalapeños. Customize with your choice of protein!",
    // "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option" },
      { "type": "gluten-free", "title": "Gluten Friendly" }
    ],
    // "nutrition": [
    //   { "value": "390", "unit": "Calories" },
    //   { "value": "1g", "unit": "Protein" },
    //   { "value": "12g", "unit": "Total Carbohydrate" },
    //   { "value": "34g", "unit": "Total Fat" },
    // ]
  },

  {
    "image": "https://balancegrille.s3.amazonaws.com/menu-images/tacos/balance-tacos-korean-bbq.png",
    "title": "korean bbq",
    "price": "$7",
    "flavorTags": [
      { "tag": "Savory" },
      { "tag": "Sweet" },
      { "tag": "Salty" }
    ],
    "description": "Classic Korean dish in taco form. Paired with our kimchi pickles for acidity, red cabbage for color and texture, and scallions to tie in all the flavors. Sauce is savory with hints of sesame oil, soy sauce, and sugar.",
    // "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegan", "title": "Vegan Option", "subtitle": "Choose Tofu or Lentils for a protein." }
    ],
    "nutrition": [
      { "value": "70", "unit": "Calories" },
      { "value": "2g", "unit": "Protein" },
      { "value": "15g", "unit": "Total Carbohydrate" },
      { "value": "0g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.com/wp-content/uploads/2022/04/balance-tacos-bang-bang.webp",
    "title": "bangbang",
    "price": "$6.5",
    "flavorTags": [
      { "tag": "Spicy 5/5" },
      { "tag": "Garlicky" },
      { "tag": "Salty" }
    ],
    "description": "Grilled chicken smothered in our spicy bangbang sauce and a cool yogurt sauce. Garnished with red onion, cilantro, and crunchy noodles.",
    "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option", "subtitle": "Choose Tofu or Lentils as a protein." }
    ],
    "nutrition": [
      { "value": "160", "unit": "Calories" },
      { "value": "3g", "unit": "Protein" },
      { "value": "17g", "unit": "Total Carbohydrate" },
      { "value": "10g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/tacos/balance-tacos-mongo-yellow-corn.png",
    "title": "mongo",
    "price": "$7.5",
    "flavorTags": [
      { "tag": "Sweet" },
      { "tag": "Smoky" },
      { "tag": "Savory" }
    ],
    "description": "A Balance classic and fan favorite! Sweet and smoky mongo sauce atop wok-fired steak, japanese mayo, spiral-cut carrots, and garnished with Balance Farms micro cilantro.",
    // "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option", "subtitle": "Choose Tofu or Lentils as a protein." }
    ],
    "nutrition": [
      { "value": "290", "unit": "Calories" },
      { "value": "1g", "unit": "Protein" },
      { "value": "20g", "unit": "Total Carbohydrate" },
      { "value": "20g", "unit": "Total Fat" },
    ]
  },

  {
    "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/tacos/balance-tacos-soho-yellow-corn.png",
    "title": "soho",
    "price": "$6.5",
    "flavorTags": [
      { "tag": "Tangy" },
      { "tag": "Citrusy" },
      { "tag": "Creamy" }
    ],
    "description": "A creamy treat! Grilled chicken, SoHo spread, tomatoes, red onion, crunchy noodles, and fresh scallions for garnish.",
    "descriptionNotes": "Contains dairy.",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option", "subtitle": "Choose Tofu or Lentils as a protein." }
    ],
    "nutrition": [
      { "value": "120", "unit": "Calories" },
      { "value": "3g", "unit": "Protein" },
      { "value": "9g", "unit": "Total Carbohydrate" },
      { "value": "9g", "unit": "Total Fat" },
    ]
  },

]
