export const salads = [
  {
    "image": "https://balancegrille.com/wp-content/uploads/2025/03/the-ranch-salad.webp",
    "title": "the ranch",
    "price": "$9",
    "flavorTags": [
      { "tag": "Salty" },
      { "tag": "Citrusy" },
      { "tag": "Dilly" },
    ],
    "description": "Bold American flavors with an Asian twist. Our special blend of locally grown greens by Balance Farms is drenched in our new Ranch dressing and topped with red onions, tomatoes, broccoli microgreens, cucumbers, and chickpeas. A perfect balance of salty, citrusy, and dilly notes, with just a hint of spice. Customize your salad bowl with your choice of protein, or enjoy it as is!",
    "dietary": [
      { "type": "vegetarian", "title": "Vegetarian Option" },
      { "type": "gluten-free", "title": "Gluten-Friendly" },
    ],
    // "nutrition": [
    //   { "value": "300", "unit": "Calories" },
    //   { "value": "6g", "unit": "Protein" },
    //   { "value": "18g", "unit": "Total Carbohydrate" },
    //   { "value": "24g", "unit": "Total Fat" },
    // ]
  },
  {
    "image": "https://balancegrille.com/wp-content/uploads/2025/03/yuzu-vinaigrette-salad.webp",
    "title": "Yuzu Vinaigrette",
    "price": "$9",
    "flavorTags": [
      { "tag": "Citrusy" },
      { "tag": "Tangy" },
      { "tag": "Savory" },
    ],
    "description": "Our special blend of locally grown greens from Balance Farms, paired with daikon radish, red cabbage, fresh cucumbers, seasonal sprouts, and scallions, is tossed in a Japanese-inspired Yuzu Vinaigrette. The bright citrus notes of yuzu and ginger complement the tangy, savory, and lightly sweet dressing. Recommended with grilled chicken or tofu.",
    "dietary": [
      { "type": "vegan", "title": "Vegan-Friendly" },
    ],
    // "nutrition": [
    //   { "value": "300", "unit": "Calories" },
    //   { "value": "6g", "unit": "Protein" },
    //   { "value": "18g", "unit": "Total Carbohydrate" },
    //   { "value": "24g", "unit": "Total Fat" },
    // ]
  },
]
