import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
// import styled, { css } from 'styled-components'

// Utilities
// import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Data
import { salads } from "../../data/salads-local";

// Components
import BackLink from '../../components/BackLink'
import SubHeader from '../../components/SubHeader'
import StepTitle from '../../components/StepTitle'
import MenuSection from '../../components/MenuSection'
import MenuItems from '../../components/MenuItems'
import MenuItem from '../../components/MenuItem'
import SaladsInstructions from '../../components/SaladsInstructions'

// Salads
class Salads extends Component {
  render() {
    return(
      <>
        <Helmet>
          <title>Salads - Menu | Balance Pan-Asian Grille</title>
          <meta name="description" content="Try our fresh new salad options, or build your own, paired with your choice of handcrafted dressings." />
        </Helmet>
        <MenuSection>
          <div class="wrap">
            <StepTitle title="Toledo" />
          </div>
          <SubHeader title="salads" />
          <MenuItems>
            {
              salads.map((menuItem, key) => {
                return (
                  <MenuItem
                    key={key}
                    image={menuItem.image}
                    title={menuItem.title}
                    price={menuItem.price}
                    flavorTags={menuItem.flavorTags}
                    description={[menuItem.description, " ", <em>{menuItem.descriptionNotes}</em>]}
                    additionalDescription={menuItem.additionalDescription}
                    dietary={menuItem.dietary}
                    nutrition={menuItem.nutrition}
                  />
                );
              })
            }
          </MenuItems>
        </MenuSection>
        <SaladsInstructions />
        <BackLink url="/menu"/>
      </>
    );
  }
}

export default Salads;
