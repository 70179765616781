export const flightFuel = [

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "Pom-Zu",
    "price": "$7",
    "description": "Pomegranate and citrusy yuzu flavor paired perfectly with a splash of butterfly tea and energizing Redbull! Topped with blueberry bubbles, served with ice. Shake lightly before drinking!",
  },

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "Black Magic",
    "price": "$7",
    "description": "Blackberry flavor paired perfectly with a splash of lemonade and energizing Red Bull! Topped with lychee bubbles, served with ice. Shake lightly before drinking!",
  },

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "Crimson Chiller",
    "price": "$7",
    "description": "Blood orange flavor paired perfectly with a splash of lemonade and energizing Red Bull! Topped with pomegranate bubbles, served with ice. Shake lightly before drinking!",
  },

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "Forest Flamingo",
    "price": "$7",
    "description": "Pineberry flavor paired perfectly with a splash of lemonade and energizing Red Bull! Topped with passionfruit bubbles, served with ice. Shake lightly before drinking!",
  },

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "What's the Fuzz",
    "price": "$7",
    "description": "White peach flavor paired perfectly with a splash of lemonade and energizing Red Bull! Topped with blueberry bubbles, served with ice. Shake lightly before drinking!",
  },

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "Coconut Craze",
    "price": "$7",
    "description": "Coconut flavor paired perfectly with a splash of butterfly tea and energizing Red Bull! Topped with strawberry bubbles, served with ice. Shake lightly before drinking!",
  },

  {
    // "image": "https://balancegrille.s3.us-east-1.amazonaws.com/menu-images/snacks/edemame.png",
    "title": "Blue Lagoon",
    "price": "$7",
    "description": "Blue raspberry flavor paired perfectly with a splash of lemonade and energizing Red Bull! Topped with kiwi bubbles, served with ice. Shake lightly before drinking!",
  },
]
