export const saladsProtein = [
  {
    "priceModifier": "+$2.00",
    "title": "grilled chicken",
    "nutrition": [
      { "value": "187", "unit": "Calories" },
      { "value": "26g", "unit": "Protein" },
      { "value": "0g", "unit": "Total Carbohydrate" },
      { "value": "9g", "unit": "Total Fat" },
    ]
  },
  {
    "title": "wok-fired steak",
    "priceModifier": "+$3.00",
    "nutrition": [
      { "value": "200", "unit": "Calories" },
      { "value": "22g", "unit": "Protein" },
      { "value": "0g", "unit": "Total Carbohydrate" },
      { "value": "12g", "unit": "Total Fat" },
    ]
  },
  {
    "priceModifier": "+$2.00",
    "title": "tofu",
    "nutrition": [
      { "value": "240", "unit": "Calories" },
      { "value": "8g", "unit": "Protein" },
      { "value": "2g", "unit": "Total Carbohydrate" },
      { "value": "22g", "unit": "Total Fat" },
    ]
  },
]
